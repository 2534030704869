import { verifyRecaptcha } from '@js/utils/Recaptcha';

export const initRecaptcha = () => {
  const form = document.querySelector('#check-email');

  if(!form) return null;

  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    try {
      await verifyRecaptcha(form);
    } catch (error) {
      const alert = document.querySelector('.js-alertRegister');

      if (!alert) return;

      alert.classList.remove('hidden');
      alert.innerHTML = error.message;

      return;
    }

    form.submit();
  });
};

initRecaptcha();
